<template>
  <div>
    <b-card class="card-custom">
      <b-form-group>
        <b-row class="d-flex px-auto">
          <b-col class="flex-grow-1"> <h3>Broadcast Messages</h3></b-col>
          <b-dropdown variant="primary">
            <template v-slot:button-content>
              <span class="svg-icon svg-icon-md"
                ><!--begin::Svg Icon | path:assets/media/svg/icons/Design/PenAndRuller.svg--><svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                      fill="#000000"
                      opacity="0.3"
                    ></path>
                    <path
                      d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                      fill="#000000"
                    ></path>
                  </g></svg
                ><!--end::Svg Icon--></span
              >
              Export
            </template>
            <b-dropdown-item>Excel</b-dropdown-item>
            <b-dropdown-item>PDF</b-dropdown-item>
          </b-dropdown>
          <b-button
            class="ml-4 mr-4 font-weight-bold"
            variant="success"
            v-b-modal.send-broadcast-modal
          >
            <span class="svg-icon svg-icon-md">
              <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Design/Flatten.svg-->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="9" cy="15" r="6"></circle>
                  <path
                    d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                    fill="#000000"
                    opacity="0.3"
                  ></path>
                </g>
              </svg>
              <!--end::Svg Icon--> </span
            >Send New Message
          </b-button>
        </b-row>
      </b-form-group>

      <b-table
        class="text-dark"
        striped
        hover
        :busy="isLoading"
        :items="getBroadcastMessages"
        :fields="fields"
      >
      </b-table>
    </b-card>

    <b-form @submit.prevent="onSubmit">
      <b-modal id="send-broadcast-modal" title="Send Brodcast Message">
        <div
          class="card-custom"
          title="Send Broadcast"
          :sub-title="`Please complete to form below to publish an update for diapps`"
          sub-title-tag="p"
        >
          <div class="row">
            <b-col md="12">
              <div class="p-2">
                <b-form-group
                  id="input-group-1"
                  label="Message title:"
                  label-for="input-1"
                >
                  <b-row>
                    <b-form-input
                      id="input-1"
                      v-model="form.title"
                      type="tel"
                      required
                      placeholder="Enter message title"
                    ></b-form-input>
                  </b-row>
                </b-form-group>
              </div>
            </b-col>
            <b-form-group class="mt-0 mb-3 col" label="Message body:">
              <b-form-textarea
                required
                :disabled="isSubmitting"
                v-model="form.message"
                max="1024"
                placeholder="Enter your message body"
                rows="3"
                max-rows="8"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <template #modal-footer="{ cancel }">
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right font-weight-bolder ml-5"
              @click="onSubmit(cancel)"
            >
              Submit
            </b-button>
            <b-button
              variant="secondary"
              size="sm"
              class="float-right font-weight-bolder"
              @click="cancel"
            >
              Cancel
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  SEND_MESSAGE_BROADCAST,
  GET_BROADCAST_MESSAGES,
} from "@/core/services/store/message_sending.module";

import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      form: {
        title: null,
        message: null,
      },
      fields: [
        {
          key: "title",
          sortable: false,
          tdClass: "text-dark",
        },
        {
          key: "message",
          sortable: false,
          tdClass: "text-dark",
          // formatter: (value) =>
          //   `${value.substring(0, 40)}${value.length > 40 ? "..." : ""}`,
        },
      ],
    };
  },
  created() {
    this.$store
      .dispatch(GET_BROADCAST_MESSAGES, this.currentUser._id)
      .then(() => {})
      .catch(console.log)
      .finally(() => {
        this.isLoading = true;
      });
  },
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(3),
      },
      message: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    onSubmit(closeModalCallback) {
      if (
        this.isSubmitting ||
        (this.form.title === null && this.form.message === null)
      )
        return;
      closeModalCallback();

      this.$bvToast.toast("Message sending in progress.", {
        title: "Sending",
        variant: "info",
        solid: true,
        appendToast: true,
      });

      this.isSubmitting = true;
      // set spinner to submit button
      // const submitButton = this.$refs["submitMessage"];
      // submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(SEND_MESSAGE_BROADCAST, {
          ...this.form,
          sentBy: this.currentUser._id,
        })
        .then((a) =>
          this.$bvToast.toast(
            "Your message has successfully been broadcasted.",
            {
              title: "Message sent",
              variant: "success",
              solid: true,
              appendToast: true,
            }
          )
        )
        .catch((e) => {
          this.$bvToast.toast(e || "Failed to send broadcast message.", {
            title: "Message sending failed",
            variant: "danger",
            solid: true,
            appendToast: true,
          });
        })
        .finally(() => {
          this.isSubmitting = false;
          // submitButton.classList.remove(
          //   "spinner",
          //   "spinner-light",
          //   "spinner-right"
          // );
        });
    },
  },
  computed: {
    ...mapGetters(["currentUser", "getBroadcastMessages"]),
  },
};
</script>